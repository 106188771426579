.adyen-checkout__issuer-button {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #b9c4c9;
  border: none;
  border-radius: 6px;
  padding: 0 12px;
  cursor: pointer;
  flex-basis: 47%;
  flex-grow: 2;
  height: 40px;
  font-size: 0.81em;
  display: flex;
  align-items: center;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out; }
  .adyen-checkout__issuer-button:active {
    color: black; }
  .adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #99A3AD; }
  .adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px #99A3AD; }
  .adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):hover {
    outline: none;
    box-shadow: inset 0 0 0 2px #99A3AD; }
  .adyen-checkout__issuer-button--selected {
    background: #fff;
    box-shadow: inset 0 0 0 2px #0075FF;
    color: #0075FF;
    font-weight: 500;
    height: 40px;
    transition: none; }

.adyen-checkout__issuer-button-img {
  max-height: 26px;
  margin-right: 8px; }
