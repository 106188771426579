.adyen-checkout__card__brands {
  display: flex;
  flex-basis: auto;
  flex-shrink: 1;
  flex-wrap: wrap;
  gap: 4px;
  height: 16px;
  overflow: hidden;
  margin-top: -8px;
  margin-bottom: 16px;
  transition: all .2s ease-out; }

.adyen-checkout__card__brands--hidden {
  opacity: 0;
  height: 0; }

.adyen-checkout__card__brands img {
  border-radius: 3px;
  height: 16px;
  width: 24px; }

.adyen-checkout__card__brands__brand-wrapper {
  display: inline-block;
  height: 16px;
  width: 24px;
  position: relative; }

.adyen-checkout__card__brands__brand-wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 3px;
  border: 1px solid rgba(0, 27, 43, 0.17); }
