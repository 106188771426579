.adyen-checkout-ctp__cards-list-dropdown .adyen-checkout__dropdown__element--disabled {
  opacity: 1; }
  .adyen-checkout-ctp__cards-list-dropdown .adyen-checkout__dropdown__element--disabled .adyen-checkout__dropdown__element__text {
    text-decoration: line-through;
    opacity: 0.4; }
  .adyen-checkout-ctp__cards-list-dropdown .adyen-checkout__dropdown__element--disabled .adyen-checkout__dropdown__element__secondary-text {
    color: #687282;
    font-weight: 500;
    line-height: 17px; }

.adyen-checkout-ctp__cards-list-dropdown .adyen-checkout__dropdown__button--disabled {
  opacity: 1; }
  .adyen-checkout-ctp__cards-list-dropdown .adyen-checkout__dropdown__button--disabled .adyen-checkout__dropdown__button__text {
    text-decoration: line-through;
    opacity: 0.4; }
  .adyen-checkout-ctp__cards-list-dropdown .adyen-checkout__dropdown__button--disabled .adyen-checkout__dropdown__button__secondary-text {
    color: #687282;
    font-weight: 500;
    opacity: 1; }
